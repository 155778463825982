import React, { FC, ReactElement } from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import { ConnectedPage } from '../connectors';
import { CasesOverview } from '../containers';
import { transformToMetaData } from '../models/Meta';

const CasesPage: FC = (): ReactElement => {
    const data = useStaticQuery(graphql`
        query CasesPageComponents {
            slices: allPrismicCases {
                edges {
                    node {
                        dataRaw
                    }
                }
            }
            cases: allPrismicCase {
                edges {
                    node {
                        id
                        first_publication_date
                        dataRaw
                        uid
                    }
                }
            }
        }
    `);

    const apiData = data.slices.edges[0].node.dataRaw;
    const cases = data.cases.edges;

    const metaData = apiData.meta.length
        ? transformToMetaData(apiData.meta[0])
        : undefined;

    return (
        <ConnectedPage metaData={metaData} className="cases-page">
            <CasesOverview cases={cases} />
        </ConnectedPage>
    );
};

export default CasesPage;


